import { ORDERAN } from 'actions/types'
import cogotoast from 'cogo-toast'
const initialState = {
    isLoading: true,
    orderData: ''
}
export default function (state = initialState, action) {
    switch (action.type) {
        case `${ORDERAN}_START`:
            cogotoast.loading('Getting data...', { position: 'top-right' })
            return { ...state, isLoading: true }
        case `${ORDERAN}_SUCCESS`:
            return { ...state, isLoading: false, orderData: action.payload }
        case `${ORDERAN}_ERROR`:
            cogotoast.error(action.payload, { position: 'top-right' })
            return { ...state, isLoading: true }
        default:
            return state
    }
}