/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { colors } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Label } from 'components';

export default [
  {
    title: 'Main',
    pages: [
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: DashboardIcon
      },
      {
        title: 'Orderan',
        href: '/orderan',
        icon: ReceiptIcon
      },
      {
        title: 'Chat',
        href: '/chat',
        icon: ChatIcon,
      },
      {
        title: 'Settings',
        href: '/settings',
        icon: SettingsIcon,
      }
    ]
  }, 
  {
    title: 'Support',
    pages: []
  }
];
