import { createAction, TOKO, ORDERAN, NOTIF } from './types'
import * as service from 'service'

export const addToko = (data) => dispatch => {
    service.post('api/toko/create', data).then(res => {
        dispatch(getdata())
        dispatch(createAction(`${NOTIF}_SUCCESS`, { payload: res.message }))
    })
}

export const getToko = () => dispatch => {
    service.get('api/toko').then(res => {
        dispatch(createAction(`${TOKO}_DATA`, { payload: res.data }))
    })
}

export const delToko = (id) => dispatch => {
    service.del(`api/toko/${id}`).then(res => {
        dispatch(getdata())
        dispatch(createAction(`${NOTIF}_SUCCESS`, { payload: res.message }))
    })
}

export const scrapData = () => dispatch => {
    service.get('api/toko/getdata').then(res => {
        dispatch(createAction(`${NOTIF}_SUCCESS`, { payload: res.message }))
    })
}

export const loadData = () => dispatch => {
    // service.get('api/toko/status').then(result => {
    //     dispatch(createAction(`${TOKO}_REQUEST`, { payload: result.data }))
    // })
}

export const relogin = () => dispatch => {
    service.get('api/toko/relogin').then(res => {
        dispatch(createAction(`${NOTIF}_SUCCESS`, { payload: res.message }))
    })
}

export const getdata = () => dispatch => {
    // dispatch(createAction(`${TOKO}_START`))
    service.get('api/toko/status').then(res => {
        if (res.status === true) {
            dispatch(createAction(`${TOKO}_REQUEST`, { payload: res.data }))
        } else {
            dispatch(createAction(`${TOKO}_REMOVE`, { payload: res.toko }))
        }
        // dispatch(createAction(`${NOTIF}_SUCCESS`, { payload: res.message }))
    })
}

export const getOrder = (id) => dispatch => {
    dispatch(createAction(`${ORDERAN}_START`))
    service.get(`api/order/${id}`).then(res => {
        if (res.status === true) {
            dispatch(createAction(`${ORDERAN}_SUCCESS`, { payload: res.data }))
        } else {
            dispatch(createAction(`${ORDERAN}_ERROR`, { payload: res.message }))
        }
    })
}
export const delOrder = (id) => dispatch => {
    service.del(`api/order/${id}`).then(res => {
        dispatch(createAction(`${ORDERAN}_DATA`, { payload: res.data }))
        dispatch(createAction(`${NOTIF}_SUCCESS`, { payload: res.message }))
    })
}