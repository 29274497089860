import React, { Suspense, useState, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { AuthGuard } from 'components'
import { NavBar, TopBar } from './components';
import { Redirect } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { loadData, getdata } from 'actions'
const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  topBar: {
    zIndex: 2,
    position: 'relative'
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto'
  }
}));

const Dashboard = props => {
  const { route } = props;
  const session = useSelector(state => state.session)
  const dispatch = useDispatch()
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const toko = useSelector(state => state.toko.toko)
  const [time, setTime] = useState(10)

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  useEffect(() => {
    let mounted = true
    let intervalId
    if (mounted) {
      if (session.isAuthenticated) {
        dispatch(getdata())
        intervalId = setInterval(() => {
          dispatch(getdata())
        }, 10000)
      }

    }
    return () => {
      clearInterval(intervalId)
      mounted = false
    }
  }, [time])

  return (
    <AuthGuard>
      <div className={classes.root}>
        <TopBar
          className={classes.topBar}
          onOpenNavBarMobile={handleNavBarMobileOpen}
        />
        <div className={classes.container}>
          <NavBar
            className={classes.navBar}
            onMobileClose={handleNavBarMobileClose}
            openMobile={openNavBarMobile}
            user={session.user}
          />
          <main className={classes.content}>
            <Suspense fallback={<LinearProgress />}>
              {renderRoutes(route.routes)}
            </Suspense>
          </main>
        </div>
      </div>
    </AuthGuard>

  );
};

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
