import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom'

import useRouter from 'utils/useRouter';

// Example of user roles: ['GUEST', 'USER', 'ADMIN'];

const AuthGuard = props => {
  const { children } = props;

  const session = useSelector(state => state.session);
  const router = useRouter();

  if(!session.isAuthenticated || !session.user){
      return <Redirect to="/auth/login"/>
  }

  // useEffect(() => {
  //   if (!session.isAuthenticated || !session.user) {
  //     router.history.push('/auth/login');
  //     return;
  //   }

  //   // if (!roles.includes(session.user.role)) {
  //   //   router.history.push('/errors/error-401');
  //   // }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [router]);
  // console.log(router)
  return <Fragment>{children}</Fragment>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
