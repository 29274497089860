import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Grid, Card, CardMedia, Divider, CardContent, Avatar, Typography } from '@material-ui/core'
import TimeAgo from 'timeago-react'
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7)
    }, 
    content: {
        padding: theme.spacing(2)
    },
    item: { 
        display: 'flex',
        marginTop: 10,
        marginLeft: 0,
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    }
}))

const CardItem = props => {
    const { data, ...rest } = props
    const classes = useStyles()
    // console.log(data)
    return (
        <Card {...rest} elevation={3} component={RouterLink} to={`/orderan/${data._id}`}>
            <Grid container className={classes.content} direction="row" spacing={1}>
                <Grid item>
                    <Avatar className={classes.large} src={data.avatar} />
                </Grid>
                <Grid item xs>
                    <Grid container direction="column">
                        <Grid item >
                            <Grid container direction="row" justify="space-between">
                                <Grid item>
                                    <Typography variant="button" gutterBottom>{data.buyer.trim()}</Typography>
                                </Grid>
                                <Grid item>
                                    <TimeAgo datetime={data.createdAt * 1000}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Typography color="textPrimary" variant="h6">
                                {data.content}
                            </Typography>
                        </Grid>
                        <Divider light/>
                        <Grid item className={classes.item}>
                            <Chip size="medium" label={data.toko.toUpperCase()}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}


CardItem.propTypes = {
    data: PropTypes.object.isRequired
}

export default CardItem