import * as actionTypes from 'actions/types'
import cogotoast from 'cogo-toast'

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  user: {},
  message: '',
  userList: [],
  show: false, 
  info: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case `${actionTypes.USER}_START`:
      return { ...state, isLoading: true }
    case `${actionTypes.USER}_CREATE`:
      return { ...state, message: action.payload }

    case `${actionTypes.USER}_SUCCESS`:
      return { ...state, isLoading: false, show: true, info: action.payload }

    case actionTypes.SESSION_LOGIN:
      return { ...state, isAuthenticated: true, isLoading: false, user: action.payload }
    case actionTypes.SESSION_ERROR:
      return { ...state, isAuthenticated: false, isLoading: false, message: action.payload }

    case actionTypes.SESSION_LOGOUT:
      return { ...state, isAuthenticated: false, isLoading: false, user: {} }

    case `${actionTypes.NOTIF}_SUCCESS`:
      cogotoast.success(action.payload, { heading: 'Success', position: 'top-right' })
      return state
    case `${actionTypes.NOTIF}_ERROR`:
      cogotoast.error(action.payload, { heading: 'Error', position: 'top-right' })
      return state
    case 'GET_USER':
      return { ...state, userList: action.payload }
    case 'CLOSE_ALERT':
      return { ...state, show: !state.show }
    default:
      return state
  }
}
