import { createAction, CHAT, NOTIF, AUTOTEXT, TOKO } from './types'
import * as service from 'service'
// import { socket } from 'config'

export const createAutoText = (data) => dispatch => {
    service.post('api/chat/autotext', data).then(res => {
        dispatch(getAutoText())
        dispatch(createAction(`${NOTIF}_SUCCESS`, { payload: res.message }))
    })
}

export const getAutoText = () => dispatch => {
    service.get('api/chat/autotext').then(res => {
        dispatch(createAction(`${AUTOTEXT}`, { payload: res.data }))
    })
}

export const delAutoText = (id) => dispatch => {
    service.del(`api/chat/autotext/${id}`).then(res => {
        dispatch(createAction(`${NOTIF}_SUCCESS`, { payload: res.message }))
        dispatch(getAutoText())
    })
}

export const getChat = () => dispatch => {
    dispatch(createAction(`${CHAT}_START`))
    service.get('api/chat').then(res => {
        console.log(res)
    })
}

export const getChatId = (id, lastId) => dispatch => {
    dispatch(createAction(`${CHAT}_START`))
    service.get(`api/chat/detail/${id}`).then(res => {
        dispatch(createAction(`${CHAT}_SUCCESS`, { payload: res.data }))
    })
}

export const sendChat = (data) => dispatch => {
    service.post('api/chat', data).then(res => {
        dispatch(createAction(`${CHAT}_SUCCESS`, { payload: res.data }))
    })
}