import * as actionTypes from 'actions/types'
import cogotoast from 'cogo-toast'
const initialState = {
    isLoading: false,
    chatData: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case `${actionTypes.CHAT}_START`:
            cogotoast.loading('Getting data...', { position: 'top-right' })
            return { ...state, isLoading: true}
        case `${actionTypes.CHAT}_SUCCESS`: 
            return { ...state, isLoading: false, chatData: action.payload}
        default:
            return state
    }
}