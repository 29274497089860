import * as types from './types'
import * as service from 'service'


export const verifyToken = (token) => dispatch => {
  service.post('auth/verify', { token: token }).then(res => {
    if (res.status) {
      service.setAuthToken(token)
      dispatch(types.createAction(`${types.SESSION_LOGIN}`, { payload: res.user }))
    }
  })
}


export const logout = () => dispatch => {
  service.setAuthToken(false)
  localStorage.removeItem('token')
  dispatch(types.createAction(`${types.SESSION_LOGOUT}`))
}

export const loginUser = (data) => dispatch => {
  dispatch(types.createAction(`${types.USER}_START`))
  service.post('auth', data).then(res => {
    if (res.status === true) {
      localStorage.setItem('token', res.token)
      localStorage.setItem('user', data.username)
      service.setAuthToken(res.token)
      dispatch(types.createAction(`${types.SESSION_LOGIN}`, { payload: res.user }))
    } else {
      dispatch(types.createAction(`${types.SESSION_ERROR}`, { payload: res.message }))
    }
  })
}

export const createUser = (data) => dispatch => {
  dispatch(types.createAction(`${types.USER}_START`))
  service.post('api/user', data).then(res => {
    dispatch(getUser())
    if (res.status === true) {
      dispatch(types.createAction(`${types.USER}_SUCCESS`, { payload: res.data }))
      dispatch(types.createAction(`${types.NOTIF}_SUCCESS`, { payload: res.message }))
    } else {

    }
  })
}

export const updateUser = (data) => dispatch => {
  dispatch(types.createAction(`${types.USER}_START`))
  service.put('api/user/data', data).then(res => {
    dispatch(getUser())
    dispatch(types.createAction(`${types.NOTIF}_SUCCESS`, { payload: res.message }))
  })
}

export const getUser = () => dispatch => {
  service.get('api/user').then(res => {
    dispatch(types.createAction('GET_USER', { payload: res.data }))
  })
}

export const deleteUser = (id) => dispatch => {
  service.del(`api/user/${id}`).then(res => {
    dispatch(getUser())
    dispatch(types.createAction(`${types.NOTIF}_SUCCESS`, { payload: res.message }))
  })
}

export const changeAccount = (data) => dispatch => {
  service.put(`api/user`, data).then(res => {
    dispatch(types.createAction(`${types.NOTIF}_SUCCESS`, { payload: res.message }))
  })
}

export const closeAlert = () => dispatch => {
  dispatch(types.createAction('CLOSE_ALERT'))
}