import axios from 'axios'
import { baseUrl } from 'config'

export function get(endpoint) {
    return axios.get(baseUrl + endpoint).then((response) => {
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}

export function put(endpoint, payload) {
    return axios.put(baseUrl + endpoint, payload).then((response) => {
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}

export function post(endpoint, payload) {
    return axios.post(baseUrl + endpoint, payload).then((response) => {
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}

export function del(endpoint) {
    return axios.delete(baseUrl + endpoint).then((response) => {
        return response.data
    }).catch((err) => {
        console.log(err)
    })
}

export function setAuthToken(token) {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
}