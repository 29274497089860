import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import chatReducer from './chatReducer';
import tokoReducer from './tokoReducer';
import orderReducer from './orderReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  chat: chatReducer, 
  toko: tokoReducer, 
  order: orderReducer
});

export default rootReducer;
