export const TOKO = "TOKO"
export const ORDERAN = "ORDERAN"
export const CHAT = "CHAT"
export const AUTOTEXT = "AUTOTEXT"
export const USER = "USER"
export const SESSION_LOGIN = "SESSION_LOGIN"
export const SESSION_ERROR = "SESSION_ERROR"
export const SESSION_LOGOUT = "SESSION_LOGOUT"
export const NOTIF = "NOTIF"

export const createAction = (type, actionProps) => {
    return {
        type,
        ...actionProps
    };
};