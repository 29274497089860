import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import ReviewCard from './ReviewCard'
import OverallReview from './OverallReview'

const useStyles = makeStyles(theme => ({
    root: {},
    review: {
        marginTop: theme.spacing(2)
    }
}));

const ReviewPanel = props => {
    const { className , items, ...rest } = props
    const classes = useStyles()

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <OverallReview ratings={items.map(review => review.rating)}/>
            {items.map(review => (
                <ReviewCard
                    className={classes.review}
                    key={review.id}
                    review={review}
                />
            ))}
        </div>
    )
}

ReviewPanel.propTypes = {
    className: PropTypes.string, 
    items: PropTypes.array.isRequired
}

export default ReviewPanel