import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth'
import ErrorLayout from './layouts/Error'
import DashboardLayout from './layouts/Dashboard'

const routes = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/dashboard" />
    },
    {
        path: '/auth',
        component: AuthLayout, 
        routes: [
            {
                path: '/auth/login', 
                exact: true, 
                component: lazy(() => import("views/Login"))
            }
        ]

    },
    {
        path: '/errors',
        component: ErrorLayout,
        routes: [
            {
                path: '/errors/error-401',
                exact: true,
                component: lazy(() => import('views/Errors/Error401'))
            },
            {
                path: '/errors/error-404',
                exact: true,
                component: lazy(() => import('views/Errors/Error404'))
            },
            {
                path: '/errors/error-500',
                exact: true,
                component: lazy(() => import('views/Errors/Error500'))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        path: '*',
        component: DashboardLayout,
        routes: [
            {
                path: '/dashboard',
                exact: true,
                component: lazy(() => import("views/Home"))
            }, 
            {
                path: '/settings', 
                exact: true, 
                component: lazy(() => import("views/Settings"))
            },
            {
                path: '/chat',
                exact: true,
                component: lazy(() => import("views/Chat"))
            }, 
            {
                path: '/chat/:id',
                exact: true,
                component: lazy(() => import("views/Chat"))
            }, 
            {
                path: '/orderan',
                exact: true,
                component: lazy(() => import("views/Orderan"))
            }, 
            {
                path: '/orderan/:id',
                exact: true,
                component: lazy(() => import("views/Orderan"))
            }, 
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    }
]

export default routes