import React, { useState, useEffect } from 'react'

const Timer = ({ hours = 0, minutes = 0, seconds = 0, fetch }) => {
    const [time, setTime] = useState({
        hours: parseInt(hours, 0),
        minute: parseInt(minutes, 10),
        second: parseInt(seconds, 0)
    })

    const tick = () => {
        if (time.hours === 0 && time.minute === 0 && time.second === 0) {
            reset()
        } else if (time.minute === 0 && time.second === 0) {
            setTime({
                hours: time.hours - 1,
                minute: 59,
                second: 59
            })
        } else if (time.second === 0) {
            setTime({
                hours: time.hours,
                minute: time.minute - 1,
                second: 59
            })
        } else {
            setTime({
                hours: time.hours,
                minute: time.minute,
                second: time.second - 1
            })
        }
    }

    const reset = () => {
        setTime({
            hours: parseInt(hours),
            minute: parseInt(minutes),
            second: parseInt(seconds)
        })
        fetch()
    }

    useEffect(() => {
        let mounted = true
        let intevalId 
        if(mounted){
            intevalId = setInterval(() => tick(), 1000)
        }
        return () => {
            mounted = false
            clearInterval(intevalId)
        }
    })

    return (
        <p>
            {`${time.hours.toString().padStart(2, '0')}: 
                ${time.minute.toString().padStart(2, '0')}:
                ${time.second.toString().padStart(2, '0')}
            `}
        </p>
    )

}

export default Timer