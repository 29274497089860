import * as actionTypes from 'actions/types'
import cogotoast from 'cogo-toast'
const intialState = {
    chat: [],
    autoText: [],
    order: [],
    toko: [],
    expired: [],
    saldo: 0,
}

export default function (state = intialState, action) {
    switch (action.type) {
        case `${actionTypes.TOKO}_START`:
            cogotoast.loading('Updating data...', { position: 'top-right' })
            return state
        case `${actionTypes.TOKO}_REQUEST`:
            if (state.chat.length !== action.payload.chat.length && state.toko.length !== action.payload.toko.length) {
                cogotoast.success('Data berhasil di update', { position: 'bottom-right' })
            } else if (state.order.length !== action.payload.order.length) {
                cogotoast.info('Ada orderan baru masuk', { position: 'top-right' })
            } else if (state.saldo > action.payload.saldo) {
                cogotoast.info(`Saldo bertambah ${action.payload.saldo}`, { position: 'top-right' })
            } else if (state.expired.length > 0) {
                cogotoast.warn('Ada  toko yang expired', { position: 'top-right' })
            }
            const newState = { ...state }
            newState.chat = action.payload.chat
            newState.order = action.payload.order
            newState.toko = action.payload.toko
            newState.saldo = action.payload.saldo
            newState.expired = action.payload.expired
            newState.autoText = action.payload.autotext
            return newState
        case `${actionTypes.TOKO}_REMOVE`:
            return { ...state, toko: action.payload }
        case `${actionTypes.AUTOTEXT}`:
            return { ...state, autoText: action.payload }
        case `${actionTypes.TOKO}_DATA`:
            return { ...state, toko: action.payload }
        case `${actionTypes.ORDERAN}_DATA`:
            return { ...state, order: action.payload }
        default:
            return state
    }
}