// const io = require('socket.io-client')
// import io from 'socket.io-client'
const production = {
    url: process.env.REACT_APP_BASE_URL
}

const development = {
    url: 'http://localhost:4000/'
}

const api = process.env.NODE_ENV === 'production' ? production : development;

export const baseUrl = api.url
// export const socket = io(api.url, { autoConnect: false, reconnection: true })

// module.exports = {
//     baseUrl: api.url, 
//     socket: io(api.url, { autoConnect: false, reconnection: true})
// }