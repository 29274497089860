import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {}
}));

const Header = props => {
    const { className, children, title, subTitle, ...rest } = props;

    const classes = useStyles();

    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}>
            <Grid
                alignItems="flex-end"
                container
                justify="space-between"
                spacing={3}>
                <Grid item>
                    <Typography
                        component="h2"
                        gutterBottom
                        variant="overline">
                        {subTitle}
                </Typography>
                    <Typography
                        component="h1"
                        variant="h3">
                        {title}
                </Typography>
                </Grid>
                <Grid item>
                    {children}
                </Grid>
            </Grid>
        </div>
    );
};

Header.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string, 
    title: PropTypes.string,
    subTitle: PropTypes.string
};

export default Header;
